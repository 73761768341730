import React, { useState } from 'react'
import SEO from '../components/SEO'
import Header from '../partials/header/Header'
import Breadcrumb from '../container/Breadcrumb/Breadcrumb'
import Footer from '../container/Footer/Footer'
import ScrollToTop from '../components/ScrollToTop.jsx'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { useEffect } from 'react'
import { fetchGlobal } from '../utils/apis'
import { DirectorEndpoint, EmployeeEndpoint } from '../APIs'

export default function TeamDetails({
  match: {
    params: { id },
  },
}) {
  const [memberDetail, setMemberDetail] = useState({})
  const type = window.location.href.split('/')[3]
  const sectionId =
    type === 'directors' ? 'board-of-directors' : 'management-team'

  useEffect(() => {
    const getMemberDetails = async () => {
      if (type === 'directors') {
        const response = await fetchGlobal(DirectorEndpoint(id))
        !response.hasError &&
          setMemberDetail(response.data.data.attributes || {})
      } else if (type === 'staff') {
        const response = await fetchGlobal(EmployeeEndpoint(id))
        !response.hasError &&
          setMemberDetail(response.data.data.attributes || {})
      }
    }

    getMemberDetails()
  }, [])

  return (
    <React.Fragment>
      <SEO title='DeltaTrust || Team Member Details' />
      <Header />
      <Breadcrumb
        image='images/bg/breadcrumb-bg-five.jpg'
        title={memberDetail.name}
        content='Home'
        contentTwo='Team'
      />
      <div className='section section-padding-t90-b100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='error-content-wrap text-center'>
                <div className='error-content'>
                  <h1 className='text-primary pb-5'>
                    {' '}
                    About {memberDetail.name}{' '}
                  </h1>
                  <div className='card'>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-12 col-md-5 py-10 '>
                          <div className='team'>
                            <div className='thumb'>
                              <div className='row justify-content-center'>
                                <div className='col-lg-10'>
                                  {!memberDetail.image?.data &&
                                  memberDetail.position
                                    ?.toLowerCase()
                                    .includes('board of director') ? (
                                    <img
                                      src='/images/placeholder/chairman_dtmb.jpeg'
                                      alt={memberDetail.name || ''}
                                      width='100%'
                                    />
                                  ) : memberDetail.image?.data ? (
                                    <img
                                      src={`${process.env.REACT_APP_STRAPI_MEDIA_URL}${memberDetail.image.data.attributes.url}`}
                                      alt={memberDetail.name || ''}
                                    />
                                  ) : (
                                    <img
                                      src='/images/placeholder/person.png'
                                      alt={memberDetail.name || ''}
                                      className='img-fluid'
                                      width='100%'
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-12 col-md-7 pt-0 pt-md-10 pb-10'>
                          <div className='card-title text-left'>
                            <h3 className='text-primary'>
                              {memberDetail.name}
                            </h3>
                            <h5 className='text-secondary'>
                              {memberDetail.position}
                            </h5>
                          </div>
                          <div className='card-text text-left my-4'>
                            <ReactMarkdown
                              rehypePlugins={[rehypeRaw]}
                              children={memberDetail.bio}
                            />
                            <Link
                              to={`/about#${sectionId}`}
                              className='btn btn-primary'
                            >
                              Back to Team
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  )
}
